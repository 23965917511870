export const eventStatus = [
  {
    text: "Waiting for ",
    // Inväntar
    children: [
      {
        code: "waiting_for_operator",
        text: "Waiting for operator"

        //  Inväntar operatör
      },
      {
        code: "waiting_for_customer_details",
        text: "Waiting for customer details"
        // Inväntar bankuppgifter
      },
      {
        code: "waiting_for_tickets",
        text: "Waiting for tickets"
        // Inväntar biljett(er)
      },
      {
        code: "waiting_for_document",
        text: "Waiting for document"
        // Inväntar dokument
      },
      {
        code: "waiting_for_silverrail",
        text: "Waiting for silverrail"
        // Inväntar svar från SilverRail
      }
    ]
  },

  {
    text: "Events",
    // Händelse
    children: [
      {
        code: "document_returned",
        text: "Tickets are returned"
        // Biljett(er) kom i retur
      },
      {
        code: "tickets_cancelled",
        text: "Tickets are cancelled"
        // Biljett(er) avbeställda
      },
      {
        code: "documents_arrived",
        text: "Documents Arrived"
      }
    ]
  },

  {
    text: "Refunding",
    // Kreditering
    children: [
      {
        code: "refund_linkonline_fe",
        text: "Refund 'Felexpedierad'"
        // Felexpedierad i Linkonline
      },
      {
        code: "refund_linkonline_at",
        text: "Refunding in Linkonline"
        // Krediterad i Linkonline
      },
      {
        code: "refund_linkonline_ao",
        text: "Refund 'annan orsak'"
        // Krediterad annan orsak i Linkonline
      },
      {
        code: "refund_linkonline_sf",
        text: "Refund 'särskilda fall'"
        // Krediterad särskilda fall i Linkonline
      },
      {
        code: "refund_klarna",
        text: "Refund with Klarna"
        // Krediterad i Klarna
      },
      {
        code: "refund_fortnox",
        text: "Refund with Fortnox"
        // Krediterad i Fortnox
      },
      {
        code: "refund_giftcard",
        text: "Refund with voucher"
        // Återbetald via tillgodokort
      },
      {
        code: "checked_by_tp",
        text: "Checked by TP"
      },
      {
        code: "authorized_by_tenant",
        text: "Authorized by Tenant"
      }
    ]
  },
  {
    text: "Others",
    // Övrigt
    children: [
      {
        code: "comment",
        text: "New comments"
        // Ny kommentar
      },
      {
        code: "INQUIRY_CUSTOMER_UPDATED",
        text: "Inquiry Customer Updated"
      }
    ]
  }
]

import Trainplanet from "@/util/trainplanet.api";

export default {
  data: () => ({
    api: Trainplanet
  }),
  methods: {
    pageLoader(value) {
      this.$store.dispatch("loading", value);
    },
    onError(message) {
      this.$store.dispatch("error", message);
    },
    onSuccess(message) {
      this.$store.dispatch("success", message);
    },
    copyToClipboard(data) {
      navigator.clipboard.writeText(data);
      this.onSuccess(`Copied: ${data}`);
    },
    getPurchaseDate(itemEvents) {
      return Array.from(itemEvents).filter(
        (data) => data.code == "ORDER_PURCHASE"
      )[0].datetime;
    },
  }
};

<template>
  <div>
    <header
      class="theme--light v-navigation-drawer elevation-1"
      style="height: 50px; border-radius: 5px; margin-bottom: 10px"
    >
      <div class="v-toolbar__content" style="height: 50px; padding: 25px">
        <div class="v-toolbar__title">
          <h4 class="black--text">Claims</h4>
        </div>
      </div>
    </header>
    <v-data-table
      :headers="headers"
      :items="inquiries"
      :server-items-length="inquiriesTotalCount"
      :loading="loading"
      class="elevation-1"
      :items-per-page="options.itemsPerPage"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': [200, 500],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="12" lg="5" md="5" sm="5" xs="5">
            <v-text-field
              v-model.trim="options.search"
              class="font-weight-bold"
              clearable
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              solo
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" xs="6">
            <v-select
              solo
              v-model="selectedValues"
              :items="tenants"
              item-text="name"
              class="font-weight-bold"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              label="Select tenants"
              multiple
              single-line
              hide-details
              chips
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 3">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text text-caption">
                  (+{{ selectedValues.length - 3 }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="2" md="2" sm="2" xs="2">
            <v-switch
              hint="Just Open"
              v-model="mustBeOpen"
              @change="listInquiries($event)"
              class="ma-0 pa-0 mt-1"
              color="greeny"
              persistent-hint
            />
          </v-col>

          <!-- <v-col cols="12" lg="1" md="1" sm="2" xs="2">
            <v-switch
              hint="Show Closed"
              class="ma-0 pa-0 mt-1"
              color="green"
              v-model="options.status"
              persistent-hint
            />
          </v-col> -->
          <!--
          <select id="select"  :items="statusFilterItem" v-model="options.status" class="my-select">
                          <optgroup
                            v-for="(item, i) in statusFilterItem"
                            :label="item.text"
                          >
                            <option
                              v-for="opt in item.children"
                              :value="opt.text"
                            >
                              {{ opt.value }}
                            </option>
                          </optgroup>
                        </select> -->
        </v-toolbar>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="text-truncate">{{ item.description }}</div>
      </template>

      <template v-slot:[`item.event`]="{ item }">
        <strong
          :class="
            colorizeStatus(
              lastEvent(item.events[0]).code,
              item.events,
              item.status
            )
          "
        >{{ getStatusText(lastEvent(item.events[0]).code) }}
        </strong>
        by
        {{ lastEvent(item.events[0]).user }}
      </template>

      <template v-slot:[`item.orders`]="{ item }">
        <div class="d-flex one-line">
          <OrderIDField
            v-if="item.orders.length > 0"
            :order="item.orders[0]"
            fromClaimPage="true"
          ></OrderIDField>
          <OrderIconsField
            v-if="item.orders.length > 0"
            :order="item.orders[0]"
            :showPrintableInfo="true"
            :showDistributedInfo="true"
            :showGroupOrderInfo="true"
            :showClaimInfo="false"
            :showArchiveOrder="true"
          ></OrderIconsField>
        </div>
      </template>

      <!-- <template v-slot:[`item.orders[0].bookings`]="{ item }">
				<div v-if="item.customer">
					<div>
						<v-icon v-if="item.customer.category === 'company'" class="mr-1" small>mdi-domain</v-icon>
						<span>{{ item.customer.firstName }} {{ item.customer.lastName }}</span>
					</div>
					<div class="grey--text text--darken-1">{{ item.customer.email }}</div>
				</div>

				<div v-else class="grey--text text--darken-1">N/A</div>
			</template> -->

      <template v-slot:[`item.tenant`]="{ item }">
        <div v-if="item.orders.length > 0">
          {{ item.orders.length > 0 && item.orders[0].seller.tenant.name }}
        </div>
      </template>

      <template v-slot:[`item.bookingid`]="{ item }">
        <template
          v-for="booking in getLegacySupportedBookingsFromInquiry(item)"
        >
          <div :key="booking.id">
            <h4 class="grey--text text--darken-4 minw100">
              <v-tooltip
                top
                :open-delay="1000"
                :close-delay="500"
                :open-on-hover="false"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="mango-red"
                    @click="copyToClipboard(booking.externalOrderId)"
                    small
                    v-on="on"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copied</span>
              </v-tooltip>
              <span class="ml-1 font-weight-bold">{{
                  booking.externalOrderId
                }}</span>
            </h4>
          </div>
        </template>
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        <!-- <div class="font-weight-bold grey--text text--darken-4">
          {{ item.updated | humanDatetime }}
        </div> -->
        <div class="grey--text text--darken-4 font-weight-medium">
          {{ item.updated | getOnlyDate }}
          <span class="grey--text text--darken-4 font-weight-regular">
            <v-icon class="mango-orange clock"
            >mdi-clock-time-eight-outline</v-icon
            >{{ item.updated | getOnlyTime }}</span
          >
        </div>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <!-- <div class="font-weight-bold grey--text text--darken-4">
          {{ item.created | humanDatetime }}
        </div> -->
        <div class="grey--text text--darken-4 font-weight-medium">
          {{ item.created | getOnlyDate }}
          <span class="grey--text text--darken-4 font-weight-regular">
            <v-icon class="mango-orange clock"
            >mdi-clock-time-eight-outline</v-icon
            >{{ item.created | getOnlyTime }}</span
          >
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.status"><span class="closed_status">CLOSED</span></div>
        <div v-if="!item.status"><span class="progress_status">OPEN</span></div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  debounce,
  getLegacySupportedBookingsFromInquiry,
} from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api";
import MultiDropdown from "@/components/base/MultiDropdown";
import moment from "moment";
import {mapGetters} from "vuex";
import common from "../../../mixins/common";
import OrderIconsField from "@/components/common/OrderIconsField";
import OrderIDField from "@/components/common/OrderIDField";
import { eventStatus } from "@/globals";

export default {
  name: "Inquiries",
  mixins: [common],
  components: {
    OrderIDField,
    OrderIconsField,
    MultiDropdown,
  },
  data: () => ({
    inquiries: [],
    firstLoad: true,
    inquiriesTotalCount: 0,
    loading: false,
    selectedValues: [],
    mustBeOpen: true,
    options: {
      status: false,
    },
    headers: [
      {
        text: "Order ID",
        value: "orders",
        sortable: false,
      },
      {
        text: "Tenant",
        value: "tenant",
        sortable: false,
      },
      {
        text: "Linkonline ID",
        value: "bookingid",
        sortable: false,
      },
      {
        text: "Last Status",
        value: "event",
        sortable: false,
      },
      {
        text: "Last updated",
        value: "updated",
      },
      {
        text: "Created",
        value: "created",
      },
      {
        value: "status",
        sortable: false,
      },
    ],
  }),
  filters: {
    humanDatetime(datetime) {
      return moment.utc(datetime).local().fromNow();
    },
    getOnlyDate(datetime) {
      return moment.utc(datetime).local().format("YYYY-MM-DD");
    },
    getOnlyTime(datetime) {
      return moment.utc(datetime).local().format("HH:mm");
    },
  },

  async mounted() {
    this.selectedValues = this.tenants.map((item) => item.id);
    await this.listInquiries(this.mustBeOpen);

  },

  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
  },

  watch: {
    options: {
      deep: true,
      handler: debounce(function () {
        if (this.tenants.length > 0) {
          if (this.firstLoad == false) {
            this.listInquiries(this.mustBeOpen);
          }
        }
      }, 500),
    },
    selectedValues: {
      deep: true,
      handler: debounce(function () {
        if (this.tenants.length > 0) {
          if (this.firstLoad == false) {
            this.listInquiries(this.mustBeOpen);
          }
        }
      }, 500),
    },
    tenants: {
      deep: true,
      handler: function (value) {
        this.selectedValues = this.tenants.map((item) => item.id);
      },
    },
  },

  methods: {
    getLegacySupportedBookingsFromInquiry,
    async onSelectedTenantsChange(selectedValues) {
      if (selectedValues.length > 0) {
        // this.helpcenter = await Trainplanet.listHelpCenter({
        //   tenantIds: selectedValues
        // });
      }
    },
    async listInquiries(mustBeOpen) {
      try {
        this.loading = true;
        const response = await Trainplanet.listInquiries({
          query: this.options.search,
          status: this.options.status,
          perPage: this.options.itemsPerPage,
          page: this.options.page,
          tenantIds: this.selectedValues,
          mustBeOpen: mustBeOpen ? mustBeOpen : false,
          //  Filter is working but we need to get only selected status on last event.
        });
        this.inquiries = response.data;
        this.inquiriesTotalCount = parseInt(response.meta.count, 10);
      } catch (error) {
        await this.$store.dispatch("error", error.message);
      } finally {
        this.loading = false;
      }
      this.firstLoad = false
    },
    statusFilter(value) {
      if (!this.statusFilterValue) {
        return true;
      }
      return value === this.statusFilterValue;
    },
    // rowClick(row) {
    //   this.$router.push({
    //     name: "inquiries-edit",
    //     params: { inquiryId: row.id }
    //   });
    // },
    getStatusText(codex) {
      const find = (array, text) => {
        let result;
        array.some(
          (o) => (result = o.text === text ? o : find(o.children || [], text))
        );
        return result;
      };
      let result_;
      try {
        result_ = find(eventStatus, codex).value;
      } catch (error) {
        result_ = codex;
      }
      return result_;
    },
    colorizeStatus(lastEvent, allEvents, status) {
      let result = "black--text";
      if (status == true) {
        return result;
      } else {
        switch (lastEvent) {
          case "checked_by_tp":
            return "greeny--text";
          case "authorized_by_tenant":
            return "third--text";
          case "refund_fortnox":
            return "cyan--text"
          default:
        }
        result = allEvents.some(
          (code) =>
            code.code === "refund_linkonline_fe" ||
            code.code === "refund_linkonline_at" ||
            code.code === "refund_linkonline_ao" ||
            code.code === "refund_linkonline_sf"
        )
          ? "primary--text"
          : "black--text";
      }
      return result;
    },
    lastEvent(event) {
      let msg = "",
        user = "",
        code = "";
      if (event !== undefined) msg = event.message;
      if (event !== undefined) code = event.code;
      if (event !== undefined) user = event.user.fullName ?? event.user.name;
      return {
        message: msg,
        user: user,
        code: code,
      };
    },
  },
};
</script>

<style>
.closed_status {
  color: #e83021;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 20px;
  min-width: 90px;
  text-align: center;
  display: inline-block;
  border: 2px solid #e83021;
  border-radius: 8px;
}

.progress_status {
  color: #5cce74;
  font-weight: bold;
  font-size: 12px;
  min-width: 90px;
  display: inline-block;
  text-align: center;
  padding: 5px 20px;
  border: 2px solid #5cce74;
  border-radius: 8px;
}

.my-select {
  height: 41px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 50%;
  padding-left: 10px;
  cursor: pointer;
}

.orderid {
  min-width: 130px;
  justify-content: center;
  font-weight: bold;
}

.clock {
  font-size: 15px !important;
  margin-top: -1px !important;
  margin-left: 5px !important;
  margin-right: 2px !important;
}
</style>

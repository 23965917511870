var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex one-line"
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "open-on-hover": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('a', {
          staticClass: "text-decoration-none",
          attrs: {
            "href": _vm.fromClaimPage == 'true' ? `/orders/inquiries/${_vm.order.inquiries}` : `/orders/${_vm.order.id}`,
            "target": "_blank"
          }
        }, [_c('v-chip', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "min-width": "130px"
          },
          attrs: {
            "color": "secondary",
            "link": "",
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.order.id) + " ")])], 1)])];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_c('v-btn', {
    staticClass: "justify-start",
    attrs: {
      "text": "",
      "href": `/orders/${_vm.order.id}`,
      "block": "",
      "small": "",
      "target": "_blank"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "accent",
      "small": ""
    }
  }, [_vm._v(" mdi-eye ")]), _c('span', {
    staticClass: "ml-1 font-weight-medium"
  }, [_vm._v("Open Order")])], 1)], 1)], 1), _c('v-list-item', [_c('v-btn', {
    staticClass: "justify-start",
    attrs: {
      "text": "",
      "small": "",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.copyToClipboard(_vm.order.id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "mango-red ",
      "small": ""
    }
  }, [_vm._v(" mdi-content-copy ")]), _c('span', {
    staticClass: "ml-1 font-weight-medium",
    on: {
      "click": function ($event) {
        return _vm.copyToClipboard(_vm.order.id);
      }
    }
  }, [_vm._v("Copy Order Id")])], 1)], 1), _vm.order.inquiries && _vm.order.inquiries.length ? _c('v-list-item', [_c('v-list-item-title', [_c('v-btn', {
    staticClass: "justify-start",
    attrs: {
      "text": "",
      "href": `/orders/inquiries/${_vm.order.inquiries}`,
      "block": "",
      "small": "",
      "target": "_blank"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "small": ""
    }
  }, [_vm._v(" mdi-lifebuoy ")]), _c('span', {
    staticClass: "ml-1 font-weight-medium"
  }, [_vm._v("Open Order Claim")])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
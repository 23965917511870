var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ml-1",
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-1",
          attrs: {
            "color": _vm.order.distributed && _vm.showDistributedInfo ? 'greeny' : 'primary'
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.order.distributed && _vm.showDistributedInfo ? "mdi-package-variant-closed-check" : "mdi-package-variant-closed") + " ")])];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.order.distributed && _vm.showDistributedInfo ? "All Tickets Distributed" : " Not Distributed"))])]), _vm.showPrintableInfo && (_vm.isAccount ? _vm.order.printedItemIds && _vm.order.printedItemIds.length : _vm.order.printable) ? _c('v-tooltip', {
    attrs: {
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-1",
          attrs: {
            "color": (_vm.isAccount && _vm.order.printedItemIds.length > 0 || _vm.order.printable) && _vm.order.distributed ? 'greeny' : 'primary'
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s((_vm.isAccount && _vm.order.printedItemIds.length > 0 || _vm.order.printable) && _vm.order.distributed ? "mdi-printer-check" : "mdi-printer") + " ")])];
      }
    }], null, false, 2783255281)
  }, [_c('span', [_vm._v(_vm._s((_vm.isAccount && _vm.order.printedItemIds.length > 0 || _vm.order.printable) && _vm.order.distributed ? "Printed" : "Printable Order(Not Printed)"))])]) : _vm._e(), _vm.order.containsGroupRow && _vm.showGroupOrderInfo ? _c('v-tooltip', {
    attrs: {
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-1 mango-red"
        }, 'v-icon', attrs, false), on), [_vm._v("mdi-alert-circle-outline ")])];
      }
    }], null, false, 3468665707)
  }, [_c('div', {
    staticStyle: {
      "max-width": "500px",
      "padding": "10px"
    }
  }, [_c('b', {
    staticStyle: {
      "font-size": "17px",
      "color": "red"
    }
  }, [_vm._v("English:")]), _vm._v(" Remember to print check tickets. Print the regular tickets first. Then go to Action, print a smaller one the total number (is it 10, print 9) This is for all travelers to have one ticket each, the control tickets refer to the main ticket. The control tickets will have the same ticket number as the main ticket. If you are unsure, double check with a colleague! "), _c('div', {
    staticClass: "mt-5"
  }, [_c('b', {
    staticStyle: {
      "font-size": "17px",
      "color": "red"
    }
  }, [_vm._v("Swedish:")]), _vm._v(" Kom ihåg att skriva ut kontrollbiljetter. Skriv ut vanliga biljetterna först. Gå sedan till Åtgärd, skriv ut en mindre en den totala antalet (är det 10, skriv ut 9) Detta är för att alla resenärer ska ha en varsin biljett, kontrollbiljetterna hänvisar till huvudbiljetten. Kontrollbiljetterna kommer att ha samma biljettnummer som huvudbiljetten. Är ni osäker, dubbelkolla med en kollega! ")])])]) : _vm._e(), _vm.order.inquiries && _vm.order.inquiries.length && _vm.showClaimInfo ? _c('v-tooltip', {
    attrs: {
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-1",
          attrs: {
            "color": "mango-red"
          },
          on: {
            "click": function ($event) {
              return _vm.navInquiries(_vm.order.inquiries);
            }
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-lifebuoy ")])];
      }
    }], null, false, 3757500445)
  }, [_c('span', [_vm._v("Has Claim Ticket")])]) : _vm._e(), _vm.order.archived && _vm.showArchiveOrder ? _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "color": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on;
        return [_c('v-icon', _vm._g({
          staticClass: "ml-1",
          attrs: {
            "color": "mango-red"
          }
        }, on), [_vm._v("mdi-archive-arrow-down ")])];
      }
    }], null, false, 1183052952)
  }, [_c('span', {
    staticStyle: {
      "display": "block",
      "text-align": "center"
    }
  }, [_vm._v(" Order Archived "), _c('br'), _vm._v(" (Archived orders are not listed "), _c('br'), _vm._v(" on the \"Search Orders\" page.) ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.inquiries,
      "server-items-length": _vm.inquiriesTotalCount,
      "loading": _vm.loading,
      "items-per-page": _vm.options.itemsPerPage,
      "options": _vm.options,
      "footer-props": {
        'items-per-page-options': [200, 500]
      }
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "lg": "5",
            "md": "5",
            "sm": "5",
            "xs": "5"
          }
        }, [_c('v-text-field', {
          staticClass: "font-weight-bold",
          attrs: {
            "clearable": "",
            "append-icon": "mdi-magnify",
            "label": "Search",
            "single-line": "",
            "hide-details": "",
            "solo": ""
          },
          model: {
            value: _vm.options.search,
            callback: function ($$v) {
              _vm.$set(_vm.options, "search", typeof $$v === 'string' ? $$v.trim() : $$v);
            },
            expression: "options.search"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "lg": "6",
            "md": "6",
            "sm": "6",
            "xs": "6"
          }
        }, [_c('v-select', {
          staticClass: "font-weight-bold",
          attrs: {
            "solo": "",
            "items": _vm.tenants,
            "item-text": "name",
            "item-value": "id",
            "menu-props": {
              maxHeight: '400'
            },
            "label": "Select tenants",
            "multiple": "",
            "single-line": "",
            "hide-details": "",
            "chips": ""
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function (_ref) {
              var item = _ref.item,
                index = _ref.index;
              return [index < 3 ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === 3 ? _c('span', {
                staticClass: "grey--text text-caption"
              }, [_vm._v(" (+" + _vm._s(_vm.selectedValues.length - 3) + ") ")]) : _vm._e()];
            }
          }]),
          model: {
            value: _vm.selectedValues,
            callback: function ($$v) {
              _vm.selectedValues = $$v;
            },
            expression: "selectedValues"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "lg": "2",
            "md": "2",
            "sm": "2",
            "xs": "2"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0 pa-0 mt-1",
          attrs: {
            "hint": "Just Open",
            "color": "greeny",
            "persistent-hint": ""
          },
          on: {
            "change": function ($event) {
              return _vm.listInquiries($event);
            }
          },
          model: {
            value: _vm.mustBeOpen,
            callback: function ($$v) {
              _vm.mustBeOpen = $$v;
            },
            expression: "mustBeOpen"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: `item.description`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-truncate"
        }, [_vm._v(_vm._s(item.description))])];
      }
    }, {
      key: `item.event`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('strong', {
          class: _vm.colorizeStatus(_vm.lastEvent(item.events[0]).code, item.events, item.status)
        }, [_vm._v(_vm._s(_vm.getStatusText(_vm.lastEvent(item.events[0]).code)) + " ")]), _vm._v(" by " + _vm._s(_vm.lastEvent(item.events[0]).user) + " ")];
      }
    }, {
      key: `item.orders`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex one-line"
        }, [item.orders.length > 0 ? _c('OrderIDField', {
          attrs: {
            "order": item.orders[0],
            "fromClaimPage": "true"
          }
        }) : _vm._e(), item.orders.length > 0 ? _c('OrderIconsField', {
          attrs: {
            "order": item.orders[0],
            "showPrintableInfo": true,
            "showDistributedInfo": true,
            "showGroupOrderInfo": true,
            "showClaimInfo": false,
            "showArchiveOrder": true
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: `item.tenant`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.orders.length > 0 ? _c('div', [_vm._v(" " + _vm._s(item.orders.length > 0 && item.orders[0].seller.tenant.name) + " ")]) : _vm._e()];
      }
    }, {
      key: `item.bookingid`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._l(_vm.getLegacySupportedBookingsFromInquiry(item), function (booking) {
          return [_c('div', {
            key: booking.id
          }, [_c('h4', {
            staticClass: "grey--text text--darken-4 minw100"
          }, [_c('v-tooltip', {
            attrs: {
              "top": "",
              "open-delay": 1000,
              "close-delay": 500,
              "open-on-hover": false
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref7) {
                var on = _ref7.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "color": "mango-red",
                    "small": ""
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.copyToClipboard(booking.externalOrderId);
                    }
                  }
                }, on), [_vm._v(" mdi-content-copy ")])];
              }
            }], null, true)
          }, [_c('span', [_vm._v("Copied")])]), _c('span', {
            staticClass: "ml-1 font-weight-bold"
          }, [_vm._v(_vm._s(booking.externalOrderId))])], 1)])];
        })];
      }
    }, {
      key: `item.updated`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "grey--text text--darken-4 font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm._f("getOnlyDate")(item.updated)) + " "), _c('span', {
          staticClass: "grey--text text--darken-4 font-weight-regular"
        }, [_c('v-icon', {
          staticClass: "mango-orange clock"
        }, [_vm._v("mdi-clock-time-eight-outline")]), _vm._v(_vm._s(_vm._f("getOnlyTime")(item.updated)))], 1)])];
      }
    }, {
      key: `item.created`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('div', {
          staticClass: "grey--text text--darken-4 font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm._f("getOnlyDate")(item.created)) + " "), _c('span', {
          staticClass: "grey--text text--darken-4 font-weight-regular"
        }, [_c('v-icon', {
          staticClass: "mango-orange clock"
        }, [_vm._v("mdi-clock-time-eight-outline")]), _vm._v(_vm._s(_vm._f("getOnlyTime")(item.created)))], 1)])];
      }
    }, {
      key: `item.status`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [item.status ? _c('div', [_c('span', {
          staticClass: "closed_status"
        }, [_vm._v("CLOSED")])]) : _vm._e(), !item.status ? _c('div', [_c('span', {
          staticClass: "progress_status"
        }, [_vm._v("OPEN")])]) : _vm._e()];
      }
    }], null, true)
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Claims")])])])]);

}]

export { render, staticRenderFns }
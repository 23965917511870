<template>
  <div class="ml-1" style="white-space: nowrap">
    <v-tooltip color="black" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          class="ml-1"
          :color="order.distributed && showDistributedInfo ? 'greeny' : 'primary'">
          {{
            order.distributed && showDistributedInfo ? "mdi-package-variant-closed-check" : "mdi-package-variant-closed"
          }}
        </v-icon>
      </template>
      <span>{{ order.distributed && showDistributedInfo ? "All Tickets Distributed" : " Not Distributed" }}</span>
    </v-tooltip>
    <v-tooltip
      v-if="showPrintableInfo && (isAccount ? order.printedItemIds && order.printedItemIds.length : order.printable)"
      color="black"
      bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          class="ml-1"
          :color="
            ((isAccount && order.printedItemIds.length > 0) || order.printable) && order.distributed
              ? 'greeny'
              : 'primary'
          ">
          {{
            ((isAccount && order.printedItemIds.length > 0) || order.printable) && order.distributed
              ? "mdi-printer-check"
              : "mdi-printer"
          }}
        </v-icon>
      </template>
      <span>{{
        ((isAccount && order.printedItemIds.length > 0) || order.printable) && order.distributed
          ? "Printed"
          : "Printable Order(Not Printed)"
      }}</span>
    </v-tooltip>
    <v-tooltip v-if="order.containsGroupRow && showGroupOrderInfo" color="black" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" class="ml-1 mango-red">mdi-alert-circle-outline </v-icon>
      </template>
      <div style="max-width: 500px; padding: 10px">
        <b style="font-size: 17px; color: red">English:</b>

        Remember to print check tickets. Print the regular tickets first. Then go to Action, print a smaller one the
        total number (is it 10, print 9) This is for all travelers to have one ticket each, the control tickets refer to
        the main ticket. The control tickets will have the same ticket number as the main ticket. If you are unsure,
        double check with a colleague!

        <div class="mt-5">
          <b style="font-size: 17px; color: red">Swedish:</b>

          Kom ihåg att skriva ut kontrollbiljetter. Skriv ut vanliga biljetterna först. Gå sedan till Åtgärd, skriv ut
          en mindre en den totala antalet (är det 10, skriv ut 9) Detta är för att alla resenärer ska ha en varsin
          biljett, kontrollbiljetterna hänvisar till huvudbiljetten. Kontrollbiljetterna kommer att ha samma
          biljettnummer som huvudbiljetten. Är ni osäker, dubbelkolla med en kollega!
        </div>
      </div>
    </v-tooltip>
    <v-tooltip v-if="order.inquiries && order.inquiries.length && showClaimInfo" color="black" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="mango-red" class="ml-1" v-bind="attrs" v-on="on" @click="navInquiries(order.inquiries)">
          mdi-lifebuoy
        </v-icon>
      </template>
      <span>Has Claim Ticket</span>
    </v-tooltip>
    <v-tooltip v-if="order.archived && showArchiveOrder" bottom color="black">
      <template v-slot:activator="{ on }">
        <v-icon color="mango-red" class="ml-1" v-on="on">mdi-archive-arrow-down </v-icon>
      </template>
      <span style="display: block; text-align: center">
        Order Archived <br />
        (Archived orders are not listed <br />
        on the "Search Orders" page.)
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "OrderIconsField",
  props: {
    order: [],
    showPrintableInfo: false,
    showDistributedInfo: false,
    showGroupOrderInfo: false,
    showClaimInfo: false,
    showArchiveOrder: true,
    isAccount: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navInquiries(value) {
      window.open("/orders/inquiries/" + value);
    },
  },
};
</script>
